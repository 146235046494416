import React from "react"


export default function AboutTo() {
    return (
        <>
            <section id="our-products" className="clearfix">
                <div className="row">
                    <div className="col-md-10 info">

                        <h3 style={{ color: "black", textAlign: "center", fontWeight: "400" }}> <b>Why at Home ?</b></h3>
                        <br></br>
                        <p className="founder-p-text" style={{ textAlign: "left", }}>Unified Home Care has been providing award winning customised Home Care for over 9 years.  Your Caregivers are a part of YOUR Team This means that there is no revolving door of Personal Support   o Nurses.With the help of your Care Planner, you choose and get to know them. This leads to an level of care for your loved one that is unsurpassed in our industry.</p>
                    </div>
                </div>



            </section>


        </>
    )
}
