import React from "react"

export default function ServicesAbout() {
    return (
        <>
            <section id="why-us" className="clearfix">

                <div className="row">
                    <div className="col-md-10 info">
                        <h3 style={{ color: "white", fontWeight: "500" }}>Industry leading care, wherever you live and when you need it at your <b style={{ color: "#e4773c", }}> HOME </b> </h3>
                        <br></br>
                        <p className="founder-p-text" style={{ color: "white", textAlign: "left",}}>
                         
                                    Unified Home Care offers a complete range of Home Care, Nursing Care and Senior Care services at your Home.
                                   
                                    Our Trusted, Reliable and Personalised caregivers offer Companionship, Support. Care, Vacation Sup. port and much more. We craft care solution that meets your needs and budget and will work with you to find the most qualified Caregiver(s) to  fit your needs.
                               
                        </p>

                    </div>


                </div>

            </section>


        </>
    )
}
