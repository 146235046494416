import React from "react"


export default function HeroAbout() {
  return (
    <section className="clearfix " className="container clearfix " className="hero-img container-home" data-aos="">
      <img style={{ width: "100%",}} src="./assets/img/Unified Home Care - About Us.png" alt="" />

    </section>
  )
}