import React from "react"
import Header from '../components/Header'
import Navigation from '../components/Navigation'
import HeroAbout from "../components/HeroAbout"
import BottomAbout from "../components/Bottom About"
import ServicesAbout from "../components/Services About"
import AboutTo from "../components/Aboutto"





export default function aboutus() {



  return (
    <>
      <Header />
      <Navigation />
      <HeroAbout/>
      <main id="main">
      
      <BottomAbout/>
     
      {/* <Hero/> */}
      
      <ServicesAbout/>
      <AboutTo/>

      </main>


    </>
  )
}